/* eslint-disable global-require */
import { Platform } from 'react-native'

const platformConfig = Platform.select({
  native: () => require('./config.native'),
  web: () => require('./config.web'),
  default: () => require('./config.web'),
})()

export const { envConfig } = platformConfig
