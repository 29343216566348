export type SportsbookStrings = 'DraftKings' | 'FanDuel' | 'Caesars' | 'BetMGM' | 'Fanatics' | 'Consensus'
export type BettingPeriodTypeStrings =
  | 'Full Game'
  | 'First Half'
  | 'Second Half'
  | 'First Quarter'
  | 'Second Quarter'
  | 'Third Quarter'
  | 'Fourth Quarter'

export interface SportsDataGameOdds {
  GameOddId: number
  Sportsbook: SportsbookStrings
  ScoreId: number
  Created: string
  Updated: string
  HomeMoneyLine: number
  AwayMoneyLine: number
  HomePointSpread: number
  AwayPointSpread: number
  HomePointSpreadPayout: number
  AwayPointSpreadPayout: number
  OverUnder: number
  OverPayout: number
  UnderPayout: number
  SportsbookId: number
  OddType: string
  SportsbookUrl: string
}

export interface GameLine {
  ScoreId: number
  Season: number
  SeasonType: number
  Week: number
  Day: string
  DateTime: string
  Status: string
  AwayTeamId: number
  HomeTeamId: number
  AwayTeamName: string
  HomeTeamName: string
  GlobalGameId: number
  GlobalAwayTeamId: number
  GlobalHomeTeamId: number
  HomeTeamScore: number
  AwayTeamScore: number
  TotalScore: number
  HomeRotationNumber: number
  AwayRotationNumber: number
  GameId: number
  PregameOdds: SportsDataGameOdds[]
  AlternateMarketPregameOdds?: SportsDataGameOdds[]
}

export interface PlayerProps {
  BettingMarketID: number
  BettingEventID: number
  BettingMarketTypeID: number
  BettingMarketType: string
  BettingBetTypeID: number
  BettingBetType: string
  BettingPeriodTypeID: number
  BettingPeriodType: BettingPeriodTypeStrings
  GlobalGameId: number
  Name: string | null
  TeamID: number | null
  TeamKey: string | null
  PlayerID: number | null
  PlayerName: string
  Created: string
  Updated: string
  AnyBetsAvailable: boolean
  IsArchived: boolean
  ArchiveLocation: string | null
  AvailableSportsbooks: any[]
  BettingOutcomes: BettingOutcome[]
  ConsensusOutcomes: ConsensusOutcome[]
}

export interface BettingOutcome {
  BettingOutcomeID: number
  BettingOutcomeType: string
  BettingOutcomeTypeID: number
  BettingBetTypeID: number
  BettingBetType: string
  Value: number
  Odds: number
  PayoutDecimal: number
  SportsBook: {
    SportsbookID: number
    Name: SportsbookStrings
  }
  PayoutAmerican: number
  Participant: string
  IsAvailable: boolean
  IsAlternate: boolean
  Created: string
  Updated: string
  Unlisted: string | null
  TeamID?: number | null
  PlayerID?: number | null
  GlobalTeamID?: number | null
  SportsbookUrl?: string | null
  IsInPlay?: boolean | null
  SportsbookMarketID?: string | null
  SportsbookOutcomeID?: string | null
}

interface ConsensusOutcome {
  NumberOfSportsbooks: number
  BettingOutcomeID: number
  BettingMarketID: number
  SportsBook: string
  BettingOutcomeTypeID: number
  BettingOutcomeType: string
  PayoutAmerican: number
  PayoutDecimal: number
  Value: number
  Participant: string
  IsAvailable: boolean
  IsAlternate: boolean
  Created: string
  Updated: string
  Unlisted: string | null
  TeamID?: number | null
  PlayerID?: number | null
  GlobalTeamID?: number | null
  SportsbookUrl?: string | null
  IsInPlay?: boolean | null
  SportsbookMarketID?: string | null
  SportsbookOutcomeID?: string | null
}

export enum SportsbookGroup {
  CONSENSUS = 'G1000',
  // GROUP_1 contains DRAFTKINGS, FANDUEL, CAESARS, BETMGM AND FANATICS
  GROUP_1 = 'G1001',
  // MORE BOOKS TO BE ADDED LATER
}

export enum SportsbookName {
  CONSENSUS = 'Consensus',
  DRAFTKINGS = 'DraftKings',
  FANDUEL = 'FanDuel',
  CAESARS = 'Caesars',
  BETMGM = 'BetMGM',
  FANATICS = 'Fanatics',
}

export enum SportsbookAbbreviations {
  'Consensus' = 'SDIO_CONS',
  'DraftKings' = 'SDIO_DRAFTKINGS',
  'FanDuel' = 'SDIO_FANDUEL',
  'BetMGM' = 'SDIO_BETMGM',
  'Caesars' = 'SDIO_CAESARS',
  'Fanatics' = 'SDIO_FANATICS',
}
