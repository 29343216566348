import { NFLPostSeasonWeeks, PostSeasonNCAAWeeksMap } from '@pff-consumer/schema'
import { strIsNFLWeekAbv } from './str-is-nfl-week-abv'
import { strIsNCAAWeekAbv } from './str-is-ncaa-week-abv'

export const formatWeekText = (description: string, currentWeek?: string) => {
  if (!currentWeek || (!strIsNFLWeekAbv(currentWeek) && !strIsNCAAWeekAbv(currentWeek))) {
    return description
  }

  if (currentWeek in NFLPostSeasonWeeks || currentWeek in PostSeasonNCAAWeeksMap) {
    return `${currentWeek} ${description}`
  }
  return `${description} ${currentWeek}`
}
