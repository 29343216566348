export const playerClassMap: Record<string, string> = {
  RSN: 'RS Sr.',
  SN: 'Sr.',
  RJ: 'RS Jr.',
  J: 'Jr.',
  RS: 'RS So.',
  S: 'So.',
  RF: 'RS Fr.',
  F: 'Fr.',
}
