import * as slugs from './teamSlugs'

const franchiseIdMap: Record<number, string> = {
  100: slugs.ABILENE_CHRISTIAN_WILDCATS,
  506: slugs.AIKEN_FALCONS,
  101: slugs.AIR_FORCE_FALCONS,
  102: slugs.AKRON_ZIPS,
  104: slugs.ALABAMA_AM_BULLDOGS,
  103: slugs.ALABAMA_CRIMSON_TIDE,
  105: slugs.ALABAMA_STATE_HORNETS,
  106: slugs.ALBANY_GREAT_DANES,
  360: slugs.ALBANY_STATE_GOLDEN_RAMS,
  107: slugs.ALCORN_STATE_BRAVES,
  361: slugs.ALDERSON_BROADDUS_BATTLERS,
  461: slugs.ALTER_KNIGHTS,
  359: slugs.AMERICAN_NFLPA,
  448: slugs.ANDERSON_REDSKINS,
  108: slugs.APPALACHIAN_STATE_MOUNTAINEERS,
  1: slugs.ARIZONA_CARDINALS,
  719: slugs.ARIZONA_HOTSHOTS,
  110: slugs.ARIZONA_STATE_SUN_DEVILS,
  109: slugs.ARIZONA_WILDCATS,
  112: slugs.ARKANSAS_PINE_BLUFF_GOLDEN_LIONS,
  111: slugs.ARKANSAS_RAZORBACKS,
  113: slugs.ARKANSAS_STATE_RED_WOLVES,
  114: slugs.ARMY_BLACK_KNIGHTS,
  2: slugs.ATLANTA_FALCONS,
  720: slugs.ATLANTA_LEGENDS,
  115: slugs.AUBURN_TIGERS,
  116: slugs.AUSTIN_PEAY_GOVERNORS,
  362: slugs.BACONE_COLLEGE_WARRIORS,
  457: slugs.BADIN_RAMS,
  117: slugs.BALL_STATE_CARDINALS,
  3: slugs.BALTIMORE_RAVENS,
  118: slugs.BAYLOR_BEARS,
  480: slugs.BEAVERCREEK_BEAVERS,
  412: slugs.BEECHWOOD_TIGERS,
  413: slugs.BELLEVUE_TIGERS,
  499: slugs.BELMONT_BISON,
  363: slugs.BENEDICT_COLLEGE_TIGERS,
  119: slugs.BETHUNE_COOKMAN_WILDCATS,
  721: slugs.BIRMINGHAM_IRON,
  364: slugs.BLUEFIELD_COLLEGE_RAMS,
  120: slugs.BOISE_STATE_BRONCOS,
  441: slugs.BOONE_COUNTY_REBELS,
  121: slugs.BOSTON_COLLEGE_EAGLES,
  365: slugs.BOWIE_STATE_BULLDOGS,
  122: slugs.BOWLING_GREEN_FALCONS,
  416: slugs.BROSSART_MUSTANGS,
  123: slugs.BROWN_BEARS,
  444: slugs.BRYAN_STATION_DEFENDERS,
  124: slugs.BRYANT_UNIVERSITY_BULLDOGS,
  125: slugs.BUCKNELL_BISON,
  4: slugs.BUFFALO_BILLS,
  126: slugs.BUFFALO_BULLS,
  127: slugs.BUTLER_BULLDOGS,
  128: slugs.BYU_COUGARS,
  497: slugs.CABELL_MIDLAND_KNIGHTS,
  129: slugs.CAL_POLY_MUSTANGS,
  130: slugs.CALIFORNIA_GOLDEN_BEARS,
  442: slugs.CAMPBELL_COUNTY_CAMELS,
  131: slugs.CAMPBELL_FIGHTING_CAMELS,
  5: slugs.CAROLINA_PANTHERS,
  417: slugs.CARROLL_COUNTY_PANTHERS,
  462: slugs.CARROLL_PATRIOTS,
  496: slugs.CATHEDRAL_FIGHTING_IRISH,
  481: slugs.CENTERVILLE_ELKS,
  132: slugs.CENTRAL_ARKANSAS_BEARS,
  133: slugs.CENTRAL_CONNECTICUT_STATE_BLUE_DEVILS,
  134: slugs.CENTRAL_MICHIGAN_CHIPPEWAS,
  366: slugs.CENTRAL_STATE_MARAUDERS,
  367: slugs.CENTRAL_WASHINGTON_WILDCATS,
  463: slugs.CHAMINADE_JULIENNE_EAGLES,
  135: slugs.CHARLESTON_SOUTHERN_BUCCANEERS,
  136: slugs.CHARLOTTE_49ERS,
  137: slugs.CHATTANOOGA_MOCS,
  6: slugs.CHICAGO_BEARS,
  368: slugs.CHOWAN_HAWKS,
  138: slugs.CINCINNATI_BEARCATS,
  7: slugs.CINCINNATI_BENGALS,
  139: slugs.CITADEL_BULLDOGS,
  369: slugs.CLARK_ATLANTA_PANTHERS,
  494: slugs.CLARKSON_ONTARIO_CHARGERS,
  140: slugs.CLEMSON_TIGERS,
  8: slugs.CLEVELAND_BROWNS,
  491: slugs.CLEVELAND_ST_IGNATIUS_WILDCATS,
  141: slugs.COASTAL_CAROLINA_CHANTICLEERS,
  469: slugs.COLERAIN_CARDINALS,
  142: slugs.COLGATE_RAIDERS,
  143: slugs.COLORADO_BUFFALOES,
  144: slugs.COLORADO_STATE_RAMS,
  145: slugs.COLUMBIA_LIONS,
  370: slugs.CONCORDIA_CARDINALS,
  146: slugs.CONNECTICUT_HUSKIES,
  438: slugs.CONNER_COUGARS,
  439: slugs.COOPER_JAGUARS,
  427: slugs.CORBIN_REDHOUNDS,
  147: slugs.CORNELL_BIG_RED,
  431: slugs.COVINGTON_CATHOLIC_COLONELS,
  371: slugs.CUMBERLAND_PHOENIX,
  9: slugs.DALLAS_COWBOYS,
  1311: slugs.DALLAS_RENEGADES,
  148: slugs.DARTMOUTH_BIG_GREEN,
  149: slugs.DAVIDSON_WILDCATS,
  150: slugs.DAYTON_FLYERS,
  414: slugs.DAYTON_GREEN_DEVILS,
  1315: slugs.DC_DEFENDERS,
  151: slugs.DELAWARE_FIGHTIN_BLUE_HENS,
  152: slugs.DELAWARE_STATE_HORNETS,
  10: slugs.DENVER_BRONCOS,
  11: slugs.DETROIT_LIONS,
  432: slugs.DIXIE_HEIGHTS_COLONELS,
  153: slugs.DRAKE_BULLDOGS,
  154: slugs.DUKE_BLUE_DEVILS,
  507: slugs.DUNBAR_WOLVERINES,
  155: slugs.DUQUESNE_DUKES,
  156: slugs.EAST_CAROLINA_PIRATES,
  456: slugs.EAST_CENTRAL_TROJANS,
  354: slugs.EAST_SHRINE,
  157: slugs.EAST_TENNESSEE_STATE_BUCCANEERS,
  158: slugs.EASTERN_ILLINOIS_PANTHERS,
  159: slugs.EASTERN_KENTUCKY_COLONELS,
  160: slugs.EASTERN_MICHIGAN_EAGLES,
  161: slugs.EASTERN_WASHINGTON_EAGLES,
  512: slugs.EDGEWOOD_COUGARS,
  372: slugs.EDWARD_WATERS_TIGERS,
  465: slugs.ELDER_PANTHERS,
  373: slugs.ELIZABETH_CITY_STATE_VIKINGS,
  162: slugs.ELON_PHOENIX,
  470: slugs.FAIRFIELD_INDIANS,
  479: slugs.FAIRMONT_FIREBIRDS,
  374: slugs.FAYETTEVILLE_STATE_BRONCOS,
  464: slugs.FENWICK_FALCONS,
  164: slugs.FLORIDA_AM_RATTLERS,
  165: slugs.FLORIDA_ATLANTIC_OWLS,
  163: slugs.FLORIDA_GATORS,
  166: slugs.FLORIDA_INTERNATIONAL_GOLDEN_PANTHERS,
  167: slugs.FLORIDA_STATE_SEMINOLES,
  375: slugs.FLORIDA_TECH_PANTHERS,
  168: slugs.FORDHAM_RAMS,
  376: slugs.FRANKLIN_GRIZZLIES,
  169: slugs.FRESNO_STATE_BULLDOGS,
  170: slugs.FURMAN_PALADINS,
  418: slugs.GALLATIN_COUNTY_WILDCATS,
  508: slugs.GAMBLE_MONTESSORI_GATORS,
  171: slugs.GARDNER_WEBB_RUNNIN_BULLDOGS,
  445: slugs.GEORGE_ROGERS_CREEK_CARDINALS,
  172: slugs.GEORGETOWN_HOYAS,
  173: slugs.GEORGIA_BULLDOGS,
  174: slugs.GEORGIA_SOUTHERN_EAGLES,
  175: slugs.GEORGIA_STATE_PANTHERS,
  176: slugs.GEORGIA_TECH_YELLOW_JACKETS,
  177: slugs.GRAMBLING_STATE_TIGERS,
  433: slugs.GRANT_COUNTY_BRAVES,
  12: slugs.GREEN_BAY_PACKERS,
  471: slugs.HAMILTON_BIG_BLUE,
  178: slugs.HAMPTON_PIRATES,
  428: slugs.HARRISON_COUNTY_THOROBREDS,
  513: slugs.HARRISON_WILDCATS,
  179: slugs.HARVARD_CRIMSON,
  180: slugs.HAWAII_WARRIORS,
  434: slugs.HIGHLANDS_BLUEBIRDS,
  488: slugs.HILLCREST_RAMS,
  520: slugs.HINSDALE_CENTRAL_RED_DEVILS,
  429: slugs.HOLMES_BULLDOGS,
  181: slugs.HOLY_CROSS_CRUSADERS,
  422: slugs.HOLY_CROSS_INDIANS,
  183: slugs.HOUSTON_BAPTIST_HUSKIES,
  182: slugs.HOUSTON_COUGARS,
  1312: slugs.HOUSTON_ROUGHNECKS,
  13: slugs.HOUSTON_TEXANS,
  184: slugs.HOWARD_BISON,
  500: slugs.HUGHES_BIG_RED,
  186: slugs.IDAHO_STATE_BENGALS,
  185: slugs.IDAHO_VANDALS,
  187: slugs.ILLINOIS_FIGHTING_ILLINI,
  188: slugs.ILLINOIS_STATE_REDBIRDS,
  189: slugs.INCARNATE_WORD_CARDINALS,
  190: slugs.INDIANA_HOOSIERS,
  191: slugs.INDIANA_STATE_SYCAMORES,
  14: slugs.INDIANAPOLIS_COLTS,
  495: slugs.INTL_SCHOOL_OF_BROWARD_PUMAS,
  192: slugs.IOWA_HAWKEYES,
  193: slugs.IOWA_STATE_CYCLONES,
  194: slugs.JACKSON_STATE_TIGERS,
  195: slugs.JACKSONVILLE_DOLPHINS,
  15: slugs.JACKSONVILLE_JAGUARS,
  196: slugs.JACKSONVILLE_STATE_GAMECOCKS,
  197: slugs.JAMES_MADISON_DUKES,
  377: slugs.JOHNSON_CENTRAL_SMITH_GOLDEN_BULLS,
  16: slugs.KANSAS_CITY_CHIEFS,
  198: slugs.KANSAS_JAYHAWKS,
  199: slugs.KANSAS_STATE_WILDCATS,
  200: slugs.KENNESAW_STATE_OWLS,
  201: slugs.KENT_STATE_GOLDEN_FLASHES,
  378: slugs.KENTUCKY_STATE_THOROBREDS,
  379: slugs.KENTUCKY_WESLEYAN_PANTHERS,
  202: slugs.KENTUCKY_WILDCATS,
  449: slugs.KINGS_KNIGHTS,
  466: slugs.LA_SALLE_LANCERS,
  446: slugs.LAFAYETTE_GENERALS,
  203: slugs.LAFAYETTE_LEOPARDS,
  492: slugs.LAKEWOOD_ST_EDWARD_EAGLES,
  472: slugs.LAKOTA_EAST_THUNDERHAWKS,
  473: slugs.LAKOTA_WEST_FIREBIRDS,
  204: slugs.LAMAR_CARDINALS,
  484: slugs.LEBANON_WARRIORS,
  205: slugs.LEHIGH_MOUNTAIN_HAWKS,
  206: slugs.LIBERTY_FLAMES,
  519: slugs.LIMA_SENIOR_SPARTANS,
  498: slugs.LINCOLN_GOLDEN_LIONS,
  380: slugs.LINCOLN_LIONS,
  514: slugs.LITTLE_MIAMI_PANTHERS,
  381: slugs.LIVINGSTONE_BLUE_BEARS,
  423: slugs.LLOYD_JUGGERNAUTS,
  27: slugs.LOS_ANGELES_CHARGERS,
  26: slugs.LOS_ANGELES_RAMS,
  1313: slugs.LOS_ANGELES_WILDCATS,
  209: slugs.LOUISIANA_MONROE_WARHAWKS,
  207: slugs.LOUISIANA_RAGIN_CAJUNS,
  208: slugs.LOUISIANA_TECH_BULLDOGS,
  210: slugs.LOUISVILLE_CARDINALS,
  450: slugs.LOVELAND_TIGERS,
  211: slugs.LSU_TIGERS,
  415: slugs.LUDLOW_PANTHERS,
  212: slugs.MAINE_BLACK_BEARS,
  382: slugs.MALONE_PIONEERS,
  489: slugs.MANCHESTER_GREYHOUNDS,
  213: slugs.MARIST_RED_FOXES,
  383: slugs.MARS_HILL_LIONS,
  214: slugs.MARSHALL_THUNDERING_HERD,
  215: slugs.MARYLAND_TERRAPINS,
  474: slugs.MASON_COMETS,
  216: slugs.MASSACHUSETTS_MINUTEMEN,
  384: slugs.MCKENDREE_BEARCATS,
  217: slugs.MCNEESE_STATE_COWBOYS,
  458: slugs.MCNICHOLAS_ROCKETS,
  435: slugs.MEADE_COUNTY_GREEN_WAVES,
  501: slugs.MEADOWDALE_LIONS,
  722: slugs.MEMPHIS_EXPRESS,
  218: slugs.MEMPHIS_TIGERS,
  219: slugs.MERCER_BEARS,
  385: slugs.MERRIMACK_WARRIORS,
  17: slugs.MIAMI_DOLPHINS,
  220: slugs.MIAMI_FL_HURRICANES,
  221: slugs.MIAMI_OH_REDHAWKS,
  485: slugs.MIAMISBURG_VIKINGS,
  223: slugs.MICHIGAN_STATE_SPARTANS,
  222: slugs.MICHIGAN_WOLVERINES,
  224: slugs.MIDDLE_TENNESSEE_BLUE_RAIDERS,
  475: slugs.MIDDLETOWN_MIDDIES,
  386: slugs.MILES_GOLDEN_BEARS,
  451: slugs.MILFORD_EAGLES,
  225: slugs.MINNESOTA_GOLDEN_GOPHERS,
  18: slugs.MINNESOTA_VIKINGS,
  226: slugs.MISSISSIPPI_REBELS,
  227: slugs.MISSISSIPPI_STATE_BULLDOGS,
  228: slugs.MISSISSIPPI_VALLEY_STATE_DELTA_DEVILS,
  387: slugs.MISSOURI_ST_MINERS,
  230: slugs.MISSOURI_STATE_BEARS,
  229: slugs.MISSOURI_TIGERS,
  467: slugs.MOELLER_FIGHTING_CRUSADERS,
  231: slugs.MONMOUTH_HAWKS,
  232: slugs.MONTANA_GRIZZLIES,
  233: slugs.MONTANA_STATE_BOBCATS,
  234: slugs.MOREHEAD_STATE_EAGLES,
  235: slugs.MORGAN_STATE_BEARS,
  515: slugs.MOUNT_HEALTHY_FIGHTING_OWLS,
  236: slugs.MURRAY_STATE_RACERS,
  358: slugs.NATIONAL_NFLPA,
  237: slugs.NAVY_MIDSHIPMEN,
  238: slugs.NEBRASKA_CORNHUSKERS,
  239: slugs.NEVADA_WOLF_PACK,
  19: slugs.NEW_ENGLAND_PATRIOTS,
  240: slugs.NEW_HAMPSHIRE_WILDCATS,
  388: slugs.NEW_MEXICO_HIGHLANDS_COWBOYS,
  241: slugs.NEW_MEXICO_LOBOS,
  242: slugs.NEW_MEXICO_STATE_AGGIES,
  20: slugs.NEW_ORLEANS_SAINTS,
  21: slugs.NEW_YORK_GIANTS,
  1316: slugs.NEW_YORK_GUARDIANS,
  22: slugs.NEW_YORK_JETS,
  425: slugs.NEWPORT_CATHOLIC_THOROUGHBREDS,
  424: slugs.NEWPORT_WILDCATS,
  243: slugs.NICHOLLS_STATE_COLONELS,
  244: slugs.NORFOLK_STATE_SPARTANS,
  389: slugs.NORTH_ALABAMA_LIONS,
  246: slugs.NORTH_CAROLINA_AT_AGGIES,
  247: slugs.NORTH_CAROLINA_CENTRAL_EAGLES,
  248: slugs.NORTH_CAROLINA_STATE_WOLFPACK,
  245: slugs.NORTH_CAROLINA_TAR_HEELS,
  249: slugs.NORTH_DAKOTA_FIGHTING_SIOUX,
  250: slugs.NORTH_DAKOTA_STATE_BISON,
  390: slugs.NORTH_GREENVILLE_CRUSADERS,
  356: slugs.NORTH_SENIOR,
  251: slugs.NORTH_TEXAS_MEAN_GREEN,
  252: slugs.NORTHERN_ARIZONA_LUMBERJACKS,
  253: slugs.NORTHERN_COLORADO_BEARS,
  254: slugs.NORTHERN_ILLINOIS_HUSKIES,
  255: slugs.NORTHERN_IOWA_PANTHERS,
  486: slugs.NORTHMONT_THUNDERBOLTS,
  516: slugs.NORTHWEST_KNIGHTS,
  257: slugs.NORTHWESTERN_STATE_DEMONS,
  256: slugs.NORTHWESTERN_WILDCATS,
  258: slugs.NOTRE_DAME_FIGHTING_IRISH,
  476: slugs.OAK_HILLS_HIGHLANDERS,
  23: slugs.LAS_VEGAS_RAIDERS,
  259: slugs.OHIO_BOBCATS,
  260: slugs.OHIO_STATE_BUCKEYES,
  391: slugs.OKLAHOMA_PANHANDLE_STATE_AGGIES,
  261: slugs.OKLAHOMA_SOONERS,
  262: slugs.OKLAHOMA_STATE_COWBOYS,
  263: slugs.OLD_DOMINION_MONARCHS,
  264: slugs.OREGON_DUCKS,
  265: slugs.OREGON_STATE_BEAVERS,
  723: slugs.ORLANDO_APOLLOS,
  419: slugs.OWEN_COUNTY_REBELS,
  266: slugs.PENN_STATE_NITTANY_LIONS,
  267: slugs.PENNSYLVANIA_QUAKERS,
  24: slugs.PHILADELPHIA_EAGLES,
  392: slugs.PIKEVILLE_BEARS,
  268: slugs.PITTSBURGH_PANTHERS,
  25: slugs.PITTSBURGH_STEELERS,
  393: slugs.POINT_SKYHAWKS,
  502: slugs.PONITZ_CAREER_TECH_GOLDEN_PANTHERS,
  269: slugs.PORTLAND_STATE_VIKINGS,
  270: slugs.PRAIRIE_VIEW_AM_PANTHERS,
  271: slugs.PRESBYTERIAN_BLUE_HOSE,
  272: slugs.PRINCETON_TIGERS,
  477: slugs.PRINCETON_VIKINGS,
  459: slugs.PURCELL_MARIAN_CAVALIERS,
  273: slugs.PURDUE_BOILERMAKERS,
  394: slugs.QUINCY_HAWKS,
  274: slugs.RHODE_ISLAND_RAMS,
  275: slugs.RICE_OWLS,
  276: slugs.RICHMOND_SPIDERS,
  509: slugs.RIVERVIEW_EAST_HAWKS,
  277: slugs.ROBERT_MORRIS_COLONIALS,
  395: slugs.ROCKY_MOUNTAIN_BATTLIN_BEARS,
  460: slugs.ROGER_BACON_SPARTANS,
  517: slugs.ROSS_RAMS,
  278: slugs.RUTGERS_SCARLET_KNIGHTS,
  440: slugs.RYLE_RAIDERS,
  279: slugs.SACRAMENTO_STATE_HORNETS,
  280: slugs.SACRED_HEART_PIONEERS,
  724: slugs.SALT_LAKE_STALLIONS,
  281: slugs.SAM_HOUSTON_STATE_BEARKATS,
  282: slugs.SAMFORD_BULLDOGS,
  725: slugs.SAN_ANTONIO_COMMANDERS,
  726: slugs.SAN_DIEGO_FLEET,
  284: slugs.SAN_DIEGO_STATE_AZTECS,
  283: slugs.SAN_DIEGO_TOREROS,
  28: slugs.SAN_FRANCISCO_49ERS,
  285: slugs.SAN_JOSE_STATE_SPARTANS,
  286: slugs.SAVANNAH_STATE_TIGERS,
  430: slugs.SCOTT_COUNTY_CARDINALS,
  1314: slugs.SEATTLE_DRAGONS,
  29: slugs.SEATTLE_SEAHAWKS,
  396: slugs.SHORTER_HAWKS,
  510: slugs.SHRODER_JAGUARS,
  443: slugs.SIMON_KENTON_PIONEERS,
  287: slugs.SMU_MUSTANGS,
  426: slugs.SOMERSET_BRIAR_JUMPERS,
  288: slugs.SOUTH_ALABAMA_JAGUARS,
  289: slugs.SOUTH_CAROLINA_GAMECOCKS,
  290: slugs.SOUTH_CAROLINA_STATE_BULLDOGS,
  291: slugs.SOUTH_DAKOTA_COYOTES,
  292: slugs.SOUTH_DAKOTA_STATE_JACKRABBITS,
  357: slugs.SOUTH_SENIOR,
  293: slugs.SOUTHEAST_MISSOURI_STATE_REDHAWKS,
  294: slugs.SOUTHEASTERN_LOUISIANA_LIONS,
  295: slugs.SOUTHERN_ILLINOIS_SALUKIS,
  296: slugs.SOUTHERN_MISS_GOLDEN_EAGLES,
  397: slugs.SOUTHERN_OREGON_RAIDERS,
  297: slugs.SOUTHERN_UNIVERSITY_JAGUARS,
  298: slugs.SOUTHERN_UTAH_THUNDERBIRDS,
  398: slugs.SOUTHWESTERN_COLLEGE_JAGUAR,
  487: slugs.SPRINGBORO_PANTHERS,
  482: slugs.SPRINGFIELD_WILDCATS,
  399: slugs.ST_ANSELM_HAWKS,
  400: slugs.ST_AUGUSTINES_FALCONS,
  447: slugs.ST_CHARLES_CARDINALS,
  299: slugs.ST_FRANCIS_PA_RED_FLASH,
  1317: slugs.ST_LOUIS_BATTLEHAWKS,
  493: slugs.ST_VINCENT_ST_MARY_FIGHTING_IRISH,
  468: slugs.ST_XAVIER_BOMBERS,
  436: slugs.ST_XAVIER_TIGERS,
  300: slugs.STANFORD_CARDINAL,
  301: slugs.STEPHEN_F_AUSTIN_LUMBERJACKS,
  302: slugs.STETSON_HATTERS,
  303: slugs.STONY_BROOK_SEAWOLVES,
  478: slugs.SYCAMORE_AVIATORS,
  304: slugs.SYRACUSE_ORANGE,
  511: slugs.TAFT_SENATORS,
  518: slugs.TALAWANDA_BRAVES,
  30: slugs.TAMPA_BAY_BUCCANEERS,
  1318: slugs.TAMPA_BAY_VIPERS,
  401: slugs.TARLETON_STATE_TEXANS,
  402: slugs.TAYLOR_TROJANS,
  305: slugs.TCU_HORNED_FROGS,
  306: slugs.TEMPLE_OWLS,
  310: slugs.TENNESSEE_MARTIN_SKYHAWKS,
  308: slugs.TENNESSEE_STATE_TIGERS,
  309: slugs.TENNESSEE_TECH_GOLDEN_EAGLES,
  31: slugs.TENNESSEE_TITANS,
  307: slugs.TENNESSEE_VOLUNTEERS,
  312: slugs.TEXAS_AM_AGGIES,
  403: slugs.TEXAS_AM_KINGSVILLE_JAVELINAS,
  311: slugs.TEXAS_LONGHORNS,
  313: slugs.TEXAS_SOUTHERN_TIGERS,
  314: slugs.TEXAS_STATE_BOBCATS,
  315: slugs.TEXAS_TECH_RED_RAIDERS,
  503: slugs.THURGOOD_MARSHALL_COUGARS,
  316: slugs.TOLEDO_ROCKETS,
  317: slugs.TOWSON_TIGERS,
  420: slugs.TRIMBLE_COUNTY_RAIDERS,
  404: slugs.TRINITY_INTERNATIONAL_TROJANS,
  437: slugs.TRINITY_SHAMROCKS,
  318: slugs.TROY_TROJANS,
  319: slugs.TULANE_GREEN_WAVE,
  320: slugs.TULSA_GOLDEN_HURRICANE,
  452: slugs.TURPIN_SPARTANS,
  405: slugs.TUSKEGEE_GOLDEN_TIGERS,
  321: slugs.UAB_BLAZERS,
  322: slugs.UC_DAVIS_AGGIES,
  323: slugs.UCF_KNIGHTS,
  324: slugs.UCLA_BRUINS,
  325: slugs.UNLV_REBELS,
  326: slugs.USC_TROJANS,
  327: slugs.USF_BULLS,
  329: slugs.UTAH_STATE_AGGIES,
  328: slugs.UTAH_UTES,
  330: slugs.UTEP_MINERS,
  331: slugs.UTSA_ROADRUNNERS,
  332: slugs.VALPARAISO_CRUSADERS,
  333: slugs.VANDERBILT_COMMODORES,
  334: slugs.VILLANOVA_WILDCATS,
  335: slugs.VIRGINIA_CAVALIERS,
  406: slugs.VIRGINIA_LYNCHBURG_DRAGONS,
  336: slugs.VIRGINIA_MILITARY_INSTITUTE_KEYDETS,
  337: slugs.VIRGINIA_TECH_HOKIES,
  338: slugs.WAGNER_SEAHAWKS,
  339: slugs.WAKE_FOREST_DEMON_DEACONS,
  453: slugs.WALNUT_HILLS_EAGLES,
  421: slugs.WALTON_VERONA_BEARCATS,
  407: slugs.WARNER_ROYALS,
  340: slugs.WASHINGTON_HUSKIES,
  32: slugs.WASHINGTON_COMMANDERS,
  341: slugs.WASHINGTON_STATE_COUGARS,
  483: slugs.WAYNE_WARRIORS,
  342: slugs.WEBER_STATE_WILDCATS,
  408: slugs.WEST_ALABAMA_TIGERS,
  454: slugs.WEST_CLERMONT_WOLVES,
  355: slugs.WEST_SHRINE,
  343: slugs.WEST_VIRGINIA_MOUNTAINEERS,
  409: slugs.WEST_VIRGINIA_WESLEYAN_BOBCATS,
  344: slugs.WESTERN_CAROLINA_CATAMOUNTS,
  504: slugs.WESTERN_HILLS_MUSTANGS,
  345: slugs.WESTERN_ILLINOIS_LEATHERNECKS,
  346: slugs.WESTERN_KENTUCKY_HILLTOPPERS,
  347: slugs.WESTERN_MICHIGAN_BRONCOS,
  410: slugs.WESTERN_NEW_MEXICO_MUSTANGS,
  411: slugs.WESTERN_OREGON_WOLVES,
  348: slugs.WILLIAM_MARY_TRIBE,
  490: slugs.WINTON_WOODS_WARRIORS,
  349: slugs.WISCONSIN_BADGERS,
  455: slugs.WITHROW_TIGERS,
  350: slugs.WOFFORD_TERRIERS,
  505: slugs.WOODWARD_BULLDOGS,
  351: slugs.WYOMING_COWBOYS,
  352: slugs.YALE_BULLDOGS,
  353: slugs.YOUNGSTOWN_STATE_PENGUINS,
}

export const alternateFranchiseIdMap: Record<number, string> = {
  307: slugs.TENNESSEE_VOLUNTEERS_ALTERNATE,
  311: slugs.TEXAS_LONGHORNS_ALTERNATE,
}

export const getTeamSlugByFranchiseId = (franchiseId: number): string => franchiseIdMap[franchiseId]

export const getAlternateTeamSlugByFranchiseId = (franchiseId: number): string => alternateFranchiseIdMap[franchiseId]
