import { type PlayerLogoTeamDetails, PlayerTheme } from '@pff-consumer/schema'
import {
  getTeamSlugByFranchiseId,
  getAlternateTeamSlugByFranchiseId,
  alternateFranchiseIdMap,
} from './getTeamSlugByFranchiseId'
import {
  getTeamIconSlugFromAbbreviation,
  getTeamAlternateIconSlugFromAbbreviation,
} from './getTeamIconSlugFromAbbreviation'
import { SVGGroup } from '../svg/svg-group'
import { isNcaa, isNfl } from './league'

export const getTeamIconAndGroup = (team: PlayerLogoTeamDetails, theme: PlayerTheme = PlayerTheme.Dark) => {
  let svgGroup = ''
  let iconName = ''
  // If Icon is not available in current map, could be old team abbreviation example OAK for Oakland Raiders
  // Look for it in getTeamSlugByFranchiseId
  if (isNfl(team.league) && team.teamAbbreviation && team.teamId) {
    if (theme === PlayerTheme.Dark) {
      iconName = getTeamIconSlugFromAbbreviation(team.teamAbbreviation) || getTeamSlugByFranchiseId(team.teamId)
    } else {
      iconName =
        getTeamAlternateIconSlugFromAbbreviation(team.teamAbbreviation) || getTeamSlugByFranchiseId(team.teamId)
    }
    svgGroup = SVGGroup.NFL
  }

  if (isNcaa(team.league) && team.teamAbbreviation && team.teamId) {
    iconName =
      alternateFranchiseIdMap[team.teamId] && theme === PlayerTheme.Light
        ? getAlternateTeamSlugByFranchiseId(team.teamId)
        : getTeamSlugByFranchiseId(team.teamId)
    svgGroup = SVGGroup.NCAA
  }

  // If icon is unavailable in getTeamIconSlugFromAbbreviation and getTeamSlugByFranchiseId
  // Display PFF logo
  if (!iconName) {
    iconName = 'pff-logo-dark'
    svgGroup = SVGGroup.Icons
  }

  return {
    iconName,
    svgGroup,
  }
}
