import { ALL_CATEGORY } from '@pff-consumer/schema'

export const contentEndpoints = {
  feedCategories: ({ client, fromWordPress }: { client: string; fromWordPress: boolean }) => {
    return { url: `/content/v1/feed-categories?client=${client}&fromWordPress=${fromWordPress}` }
  },
  feed: ({
    feedCategorySlug,
    start,
    limit,
    showPremium = false,
    client,
    fromWordPress,
  }: {
    feedCategorySlug: string | undefined
    start: number
    limit: number
    showPremium: boolean
    client: string
    fromWordPress: boolean
  }) => {
    const feedCategoryFilter: string =
      feedCategorySlug && feedCategorySlug !== ALL_CATEGORY.slug ? `feedCategories=${feedCategorySlug}&` : ''
    return {
      url: `/content/v1/feed?${feedCategoryFilter}start=${start}&limit=${limit}&showPremium=${showPremium}&client=${client}&fromWordPress=${fromWordPress}`,
    }
  },
  latestArticles: ({ client, fromWordPress }: { client: string; fromWordPress: boolean }) => {
    return { url: `/content/v1/articles?client=${client}&fromWordPress=${fromWordPress}` }
  },
  articleById: ({
    id,
    client,
    fromWordPress,
    isLoggedIn = false,
  }: {
    id: number
    client: string
    fromWordPress: boolean
    isLoggedIn: boolean
  }) => {
    return {
      url: `/content/v1/articles/${id}?client=${client}&fromWordPress=${fromWordPress}&isLoggedIn=${isLoggedIn}`,
    }
  },
  articleBySlug: ({
    slug,
    client,
    fromWordPress,
    isLoggedIn = false,
  }: {
    slug: string
    client: string
    fromWordPress: boolean
    isLoggedIn: boolean
  }) => {
    return {
      url: `/content/v1/articles?slugs=["${slug}"]&client=${client}&fromWordPress=${fromWordPress}&isLoggedIn=${isLoggedIn}`,
    }
  },
  bookmarkedArticles: ({ ids, client, fromWordPress }: { ids: number[]; client: string; fromWordPress: boolean }) => {
    const stringifiedIds = JSON.stringify(ids)
    return {
      url: `/content/v1/articles?ids=${stringifiedIds}&abbreviated=true&client=${client}&fromWordPress=${fromWordPress}`,
    }
  },
  ladningPageContent: ({ pageTopic }: { pageTopic: string }) => {
    return { url: `/content/v1/landing-pages/${pageTopic}` }
  },
}
