import {
  FlexPostionPills,
  type FlexPostionPillsKey,
  type DraftedPlayerInfo,
  type LeagueSettingsStarters,
} from '@pff-consumer/schema'

type LeagueStarterPositions = {
  [position: string]: number
}

type DraftedPositionsCount = {
  [position: string]: number
}

type PositionsMap = {
  [key: string]: string[]
}

export const positionsMap: PositionsMap = {
  QB: ['QB'],
  WR: ['WR'],
  RB: ['RB'],
  TE: ['TE'],
  K: ['K'],
  DST: ['DST'],
  [FlexPostionPills.WRRB]: ['WR', 'RB'],
  [FlexPostionPills.WRTE]: ['WR', 'TE'],
  [FlexPostionPills.FLEX]: ['WR', 'RB', 'TE'],
  [FlexPostionPills.SUPERFLEX]: ['WR', 'RB', 'TE', 'QB'],
  BN: ['QB', 'WR', 'RB', 'TE', 'K', 'DST'],
}

/**
 * Maps an input flex position key to a corresponding new key designated for additional flex positions
 * @param positionKey
 * @returns "W/R/T" | "W/R" | "W/T" | "Q/W/R/T" | input key as-is
 */
const mapAdditionalFlexPositionKey = (positionKey: FlexPostionPillsKey) => {
  switch (FlexPostionPills[positionKey]) {
    case FlexPostionPills.FLEX:
      return FlexPostionPills.FLEX
    case FlexPostionPills.WRRB:
      return FlexPostionPills.WRRB
    case FlexPostionPills.WRTE:
      return FlexPostionPills.WRTE
    case FlexPostionPills.SUPERFLEX:
      return FlexPostionPills.SUPERFLEX
    default:
      return positionKey
  }
}

export const getPositionsThatCanBeDrafted = (
  userPicks: DraftedPlayerInfo[],
  leagueSettingsStarters: LeagueSettingsStarters,
  benchCount: number
): string[] => {
  const BENCH_POSITION: string = 'BN'
  const PREFIX_CHARS_TO_BE_REMOVED: number = 9

  const filledPositions: DraftedPositionsCount = {}

  const startingPositions: LeagueStarterPositions = Object.entries(leagueSettingsStarters).reduce(
    (acc, [key, value]) => {
      // Slicing 9 because, we need to remove the prefix "starting_" that comes from League settings
      const positionKey = mapAdditionalFlexPositionKey(
        key.slice(PREFIX_CHARS_TO_BE_REMOVED).toUpperCase() as FlexPostionPillsKey
      )
      return { ...acc, [positionKey]: value }
    },
    { BN: benchCount }
  )

  // Helper function to check if a position is already filled
  const canPositionBeFilled = (position: string): boolean => {
    const positionFilledStatus =
      startingPositions[position] > 0 &&
      (!filledPositions[position] || filledPositions[position] < startingPositions[position])
    return positionFilledStatus
  }

  const canWRRBPositionBeFilled = (position: string): boolean => {
    const flxOptions = ['WR', 'RB']
    return flxOptions.includes(position) && canPositionBeFilled(FlexPostionPills.WRRB)
  }

  const canWRTEPositionBeFilled = (position: string): boolean => {
    const flxOptions = ['WR', 'TE']
    return flxOptions.includes(position) && canPositionBeFilled(FlexPostionPills.WRTE)
  }

  const canFlexPositionBeFilled = (position: string): boolean => {
    // Try to fill the FLX position with WR, RB, or TE if available
    const flxOptions = ['WR', 'RB', 'TE']
    return flxOptions.includes(position) && canPositionBeFilled(FlexPostionPills.FLEX)
  }

  const canSuperFlexPositionBeFilled = (position: string): boolean => {
    // Try to fill the SFLX position with WR, RB,TE or QB if available
    const sflxOptions = ['WR', 'RB', 'TE', 'QB']
    return sflxOptions.includes(position) && canPositionBeFilled(FlexPostionPills.SUPERFLEX)
  }

  // Iterate through the players and assign them to their respective positions
  userPicks.forEach((player) => {
    const position = player?.position
    if (!position) {
      return
    }

    if (canPositionBeFilled(position)) {
      filledPositions[position] = filledPositions[position] + 1 || 1
    } else if (canWRRBPositionBeFilled(position)) {
      // If the position is already filled, check to fill W/R position
      filledPositions[FlexPostionPills.WRRB] = filledPositions[FlexPostionPills.WRRB] + 1 || 1
    } else if (canWRTEPositionBeFilled(position)) {
      // If the position is already filled, check to fill W/T position
      filledPositions[FlexPostionPills.WRTE] = filledPositions[FlexPostionPills.WRTE] + 1 || 1
    } else if (canFlexPositionBeFilled(position)) {
      // If the position is already filled, check to fill FLX position
      filledPositions[FlexPostionPills.FLEX] = filledPositions[FlexPostionPills.FLEX] + 1 || 1
    } else if (canSuperFlexPositionBeFilled(position)) {
      // If the positions & FLX are already filled, check to fill SFLX position
      filledPositions[FlexPostionPills.SUPERFLEX] = filledPositions[FlexPostionPills.SUPERFLEX] + 1 || 1
    } else {
      // If all positions, Flex and SuperFlex are filled, fill the player at the BN position of the roster
      filledPositions[BENCH_POSITION] = filledPositions[BENCH_POSITION] + 1 || 1
    }
  })

  const possiblePositions: string[] = []

  Object.entries(positionsMap).forEach(([key, positions]) => {
    if (canPositionBeFilled(key)) {
      possiblePositions.push(...positions)
    }
  })

  return [...new Set(possiblePositions)]
}
