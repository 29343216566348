import { BetOptionType, BetStatus, PlayerPropKey } from '../betting/betting-product'
import { SDKPlayerPropGradeAnalysis } from './player-props-by-season-week'

export interface PlayerPropBet {
  eventId: number
  playerId: number
  teamId: number
  odds: number
  value: number | string
  name: string
  option: BetOptionType
  marketType: string
  market: PlayerPropKey
  sportsbookEventId: string
  sportsbookMarketId: string
  sportsbookOptionId: string
  sportsbookBetId: string
  status: BetStatus
}

export interface PlayerPropBetWithAnalysis extends PlayerPropBet {
  analysis: SDKPlayerPropGradeAnalysis
}

export interface PaginatedPlayerPropBets {
  playerProps: Array<PlayerPropBet | PlayerPropBetWithAnalysis>
  eventIds?: number[]
  total: number
  nextPage?: number | null
}

export interface PlayerPropSection {
  eventIds?: number[]
  total: number
  nextPage?: number | null
  header: string
  market: PlayerPropKey
  data: Array<SingleMarket | OverUnderMarket>
}

export type PlayerPropBetsGroup = Record<string, PaginatedPlayerPropBets>

export interface SingleMarket {
  eventId: number
  playerId: number
  singleBet: PlayerPropBet
}
export interface OverUnderMarket extends Pick<SingleMarket, 'eventId' | 'playerId'> {
  overBet: PlayerPropBet
  underBet: PlayerPropBet
}

export function isSingleMarket(obj: any): obj is SingleMarket {
  return 'singleBet' in obj
}

export function isOverUnderMarket(obj: any): obj is OverUnderMarket {
  return 'overBet' in obj && 'underBet' in obj
}

export function isPlayerPropBetWithAnalysis(obj: any): obj is PlayerPropBetWithAnalysis {
  return 'analysis' in obj
}

// the APIs return upcoming bets by default by providing this for eventStatus we can return bets for completed and in progress events
export const SUPER_BOWL_EVENT_STATUS = 'Scheduled,InProgress,Final,F/OT'
