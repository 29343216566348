export enum SportsDataInjuryStatus {
  Probable = 'Probable',
  Questionable = 'Questionable',
  Doubtful = 'Doubtful',
  Out = 'Out',
}

export interface SportsDataNFLPlayerInjury {
  PlayerID: number
  Team: string
  Number: number
  FirstName: string
  LastName: string
  Position: string
  Status: 'Active' | 'Inactive'
  Height: string
  Weight: number
  BirthDate: string
  College: string
  Experience: number
  FantasyPosition: string
  Active: boolean
  PositionCategory: string
  Name: string
  Age: number
  ExperienceString: string
  BirthDateString: string
  PhotoUrl: string
  ByeWeek: number
  UpcomingGameOpponent: string
  UpcomingGameWeek: number
  ShortName: string
  AverageDraftPosition: number
  DepthPositionCategory: string
  DepthPosition: string
  DepthOrder: number
  DepthDisplayOrder: number
  CurrentTeam: number
  CollegeDraftTeam: string
  CollegeDraftYear: number
  CollegeDraftRound: number
  CollegeDraftPick: number
  IsUndraftedFreeAgent: boolean
  HeightFeet: number
  HeightInches: number
  UpcomingOpponentRank: number
  UpcomingOpponentPositionRank: number
  CurrentStatus: string
  UpcomingSalary: number
  FantasyAlarmPlayerID: number
  SportRadarPlayerID: string
  RotoworldPlayerID: number
  RotoWirePlayerID: number
  StatsPlayerID: number
  SportsDirectPlayerID: number
  XmlTeamPlayerID: number
  FanDuelPlayerID: number
  DraftKingsPlayerID: number
  YahooPlayerID: number
  InjuryStatus: SportsDataInjuryStatus
  InjuryBodyPart: string
  InjuryStartDate: string
  InjuryNotes: string
  FanDuelName: string
  DraftKingsName: string
  YahooName: string
  FantasyPositionDepthOrder: number
  InjuryPractice: string
  InjuryPracticeDescription: string
  DeclaredInactive: boolean
  UpcomingFanDuelSalary: number
  UpcomingDraftKingsSalary: number
  UpcomingYahooSalary: number
  TeamID: number
  GlobalTeamID: number
  FantasyDraftPlayerID: number
  FantasyDraftName: string
  UsaTodayPlayerID: number
  UsaTodayHeadshotUrl: string
  UsaTodayHeadshotNoBackgroundUrl: string
  UsaTodayHeadshotUpdated: string
  UsaTodayHeadshotNoBackgroundUpdated: string
}

export interface SportsDataCollegePlayerInjury {
  PlayerID: number
  FirstName: string
  LastName: string
  TeamID: number
  Team: string
  Jersey: number
  Position: string
  PositionCategory: string
  Class: string
  Height: string
  Weight: number
  BirthCity: string
  BirthState: string
  Updated: string
  Created: string
  GlobalTeamID: number
  InjuryStatus: SportsDataInjuryStatus
  InjuryBodyPart: string
  InjuryStartDate: string
  InjuryNotes: string
  DeclaredInactive?: boolean
}
