import { useState, useEffect } from 'react'

declare global {
  interface Window {
    sessionData?: {
      grootUid?: string
      isPremiumSubscriber?: boolean
      showAds?: boolean
    }
  }
}

export const useUserSession = () => {
  const [isPremiumUser, setIsPremiumUser] = useState<boolean>(false)
  const [isLoggedInUser, setIsLoggedInUser] = useState<boolean>(false)

  useEffect(() => {
    setIsPremiumUser(!!window?.sessionData?.isPremiumSubscriber)
    setIsLoggedInUser(!!window?.sessionData?.grootUid)
  }, [])

  return { isPremiumUser, isLoggedInUser }
}
