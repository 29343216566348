import { createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit'
import type { SubscriptionType, User } from '@pff-consumer/schema'
import { envConfig } from '@pff-consumer/utils'
import { getGrootTokenFromJwt } from './util/getGrootTokenFromJwt'
import { checkHasCurrentSubscription } from './util/checkHasCurrentSubscription'
import { checkHasExpiredSubscription } from './util/checkHasExpiredSubscription'

export type MarketingCategory = 'NFL' | 'CFB' | 'Betting' | 'Fantasy' | 'DFS' | 'NFL Draft'

export const ACCOUNT_KEY = 'account'

export interface AccountError {
  error: string
  message: string
  status: number
}

export interface AccountState {
  user: User | null
  error: AccountError | null
  loading: boolean
  token: string | null
  isPremium: boolean
  subType: SubscriptionType
  channel: string | null
  redirectScreen?: string
  isExpired: boolean
  isLoggedIn: boolean
  isCreatingAccount: boolean
  isNotificationsEnabled: boolean
  isEmailNotificationEnabled: boolean
  notificationSubjects: Record<MarketingCategory, boolean>
}

export const authStateAdapter = createEntityAdapter<AccountState>()

export const initialAuthState: AccountState = authStateAdapter.getInitialState({
  user: null,
  error: null,
  loading: false,
  token: null,
  isPremium: false,
  subType: 'Free',
  channel: null,
  isExpired: false,
  isLoggedIn: false,
  isCreatingAccount: false,
  isNotificationsEnabled: false,
  isEmailNotificationEnabled: false,
  notificationSubjects: {
    NFL: false,
    CFB: false,
    Betting: false,
    Fantasy: false,
    DFS: false,
    'NFL Draft': false,
  },
  grootToken: null,
})

const getSubscriptionTypeFromProductId = (productId?: string): SubscriptionType => {
  switch (productId) {
    case envConfig.YEARLY_SUB:
      return 'Annual'
    case envConfig.MONTHLY_SUB:
      return 'Monthly'
    default:
      return 'Free'
  }
}

export const accountSlice = createSlice({
  name: ACCOUNT_KEY,
  initialState: initialAuthState,
  reducers: {
    updateUser: (state, { payload }: { payload: { user: User | null; token: string | null } }) => {
      state.token = payload.token
      state.isLoggedIn = true
      state.user = payload.user
      if (payload.user && payload.user.channel) {
        state.channel = payload.user.channel
      }

      const hasCurrentSubscription = checkHasCurrentSubscription(payload.user)
      const hasExpiredSubscription = !hasCurrentSubscription && checkHasExpiredSubscription(payload.user)

      state.isPremium = hasCurrentSubscription
      state.isExpired = hasExpiredSubscription
      state.subType = getSubscriptionTypeFromProductId(payload?.user?.product_id)
    },
    updateLoading: (state, { payload }) => {
      state.loading = payload.loading
    },
    updateError: (state, { payload }) => {
      if (payload.error) {
        const newError: AccountError = {
          error: payload.error.data.error,
          message: payload.error.data.message,
          status: payload.error.status,
        }

        state.error = newError
      }
    },
    resetError: (state) => {
      state.error = null
    },
    deleteToken: (state) => {
      state.token = null
    },
    updateSubscription: (state, { payload }) => {
      state.isPremium = payload.isPremium
      state.isExpired = false
      if (state.user) {
        state.user = { ...state.user, channel: 'app', product_id: payload.product_id }
        state.subType = getSubscriptionTypeFromProductId(payload?.product_id)
      }
    },
    updateChannel: (state, { payload }) => {
      state.channel = payload
    },
    setRedirectScreen: (state, { payload }: { payload: { screen?: string } }) => {
      state.redirectScreen = payload.screen
    },
    setIsLoggedIn: (state, { payload }) => {
      state.isLoggedIn = payload.isLoggedIn
    },
    updateToken: (state, { payload }) => {
      state.token = payload.token
    },
    setIsEmailNotificationEnabled: (state, { payload }) => {
      state.isEmailNotificationEnabled = payload
    },
    setNotificationSubjects: (
      state,
      {
        payload,
      }: {
        payload: {
          key: string
          value: boolean
        }
      }
    ) => {
      state.notificationSubjects = {
        ...state.notificationSubjects,
        [payload.key]: payload.value,
      }
    },
    setIsCreatingAccount: (state, { payload }) => {
      state.isCreatingAccount = payload.isCreatingAccount
    },
    setIsNotificationsEnabled: (state, { payload }) => {
      state.isNotificationsEnabled = payload.notificationsEnabled
    },
    updateUserObject: (state, { payload }) => {
      state.user = payload.user
    },
  },
})

export const accountReducer = accountSlice.reducer
export const accountActions = accountSlice.actions

export const getAccountState = (rootState: { [x: string]: AccountState }): AccountState => {
  return rootState[ACCOUNT_KEY]
}

export const accountSelectors = {
  getEmailNotification: createSelector(getAccountState, (state: AccountState) => state.isEmailNotificationEnabled),
  getNotificationSubjects: createSelector(getAccountState, (state: AccountState) => state.notificationSubjects),
  getLoadingState: createSelector(getAccountState, (state: AccountState) => state.loading),
  getToken: createSelector(getAccountState, (state: AccountState) => state.token),
  getUser: createSelector(getAccountState, (state: AccountState) => state.user),
  getError: createSelector(getAccountState, (state: AccountState) => state.error),
  getIsPremium: createSelector(getAccountState, (state: AccountState) => state.isPremium),
  getIsExpired: createSelector(getAccountState, (state: AccountState) => state.isExpired),
  getSubscriptionType: createSelector(getAccountState, (state: AccountState) => state.subType),
  getChannel: createSelector(getAccountState, (state: AccountState) => state.channel),
  getLoginRedirect: createSelector(getAccountState, (state: AccountState) => state.redirectScreen),
  getIsLoggedIn: createSelector(getAccountState, (state: AccountState) => state.isLoggedIn),
  getIsCreatingAccount: createSelector(getAccountState, (state: AccountState) => state.isCreatingAccount),
  getIsNotificationsEnabled: createSelector(getAccountState, (state: AccountState) => state.isNotificationsEnabled),
  getGrootAccessToken: createSelector(getAccountState, (state: AccountState) => {
    if (!state.token) {
      return ''
    }
    return getGrootTokenFromJwt(state.token)
  }),
}
