import type { LeagueSettings, LeagueSettingsUpsert } from './league-settings'

export enum LeagueTypes {
  STANDARD = 'Non-PPR',
  PPR = 'PPR',
  HALF_PPR = '.5 PPR',
  TWO_QB_PPR = 'Two QB PPR',
  IDP_BALANCED = 'IDP Balanced',
  IDP_TACKLE_HEAVY = 'IDP Tackle Heavy',
  IDP_BIG_PLAY = 'IDP Big Play',
  CUSTOM = 'Custom',
}
export type LeagueType = `${LeagueTypes}`

export enum LeagueSettingsNames {
  ESPN_DEFAULTS = 'ESPN Defaults',
  YAHOO_DEFAULTS = 'Yahoo Defaults',
  CBS_DEFAULTS = 'CBS Sports Defaults',
  NFL_DEFAULTS = 'NFL.com Defaults',
  PFF_DEFAULTS = 'PFF Defaults',
  STANDARD = 'Non-PPR',
  PPR = 'PPR',
  HALF_PPR = '.5 PPR',
  TWO_QB_PPR = 'Two QB PPR',
  IDP_BALANCED = 'IDP Balanced',
  IDP_TACKLE_HEAVY = 'IDP Tackle Heavy',
  IDP_BIG_PLAY = 'IDP Big Play',
  DRAFT_KINGS = 'DraftKings',
  FAN_DUEL = 'FanDuel',
  YAHOO_DFS = 'Yahoo DFS',
  DRAFT_BEST_BALL = 'Draft.com BestBall',
  FOOTBALL10_BEST_BALL = 'Football10s BestBall',
}
export type LeagueSettingsNameType = `${LeagueSettingsNames}`

export enum DraftTypes {
  SNAKE = 'Snake',
  LINEAR = 'Linear',
  AUCTION = 'Auction',
  ROOKIE_SNAKE = 'Rookie Snake',
  ROOKIE_LINEAR = 'Rookie Linear',
  THIRD_ROUND_REVERSAL = '3RR',
}
export type DraftType = `${DraftTypes}`

export enum KeeperTypes {
  NONE = 'None',
  BY_TEAM = 'By Team',
}
export type KeeperType = `${KeeperTypes}`

export enum LineupTypes {
  SET_WEEKLY = 'Set Weekly',
  BEST_BALL = 'Best Ball',
  DYNASTY = 'Dynasty',
}
export type LineupType = `${LineupTypes}`

export enum HostedSites {
  ESPN = 'espn',
  YAHOO = 'yahoo',
  CBS = 'cbs',
  NFL = 'nfl',
  MY_FANTASY_LEAGUE = 'mfl',
  UNPLUGGED = 'unplugged',
  SLEEPER = 'sleeper',
  NONE = 'other',
}
export type HostedSite = `${HostedSites}`

export interface Roster {
  player_id: string
  starter: string
}

export interface LeagueInfoForAnalytics {
  leagueSite: string
  leagueName: string
  source?: string
  leagueCreatedAt?: string
  leagueType?: string
  lineupType?: string
  draftType?: string
  leagueScoringType?: string
  keepers?: number
}

export interface League {
  id: number
  name: string
  size: number
  user_id: number | null
  created_at: string
  updated_at: string
  hosted_league_id: string | null
  auction_budget: number | null
  league_type: LeagueType
  custom_league_settings_id: number | null
  season: number
  team_name: string
  keepers: number
  hosted_site: HostedSite
  keeper_type: KeeperType
  draft_type: DraftType
  draft_completed: boolean
  roster?: Roster[] | null
  lineup_type: LineupType
  hosted_site_url: string | null
  league_settings?: LeagueSettings | null
  time_per_pick: number
  draft_date: string | null
  hosted_owner_id: string | null
  is_commissioner: boolean | null
}

export interface LeagueUpsert {
  name: string
  size: number
  season?: number
  team_name?: string
  league_type?: LeagueType
  draft_type?: DraftType
  keeper_type?: KeeperType
  lineup_type?: LineupType
  hosted_site?: HostedSite
  hosted_site_url?: string | null
  keepers?: number
  auction_budget?: number | null
  hosted_league_id?: string | null
  draft_completed?: boolean
  time_per_pick?: number
  draft_date?: string | null
  hosted_owner_id?: string
  is_commissioner?: boolean | null
}

export interface DropdownOption {
  name: string
  value: string | number
  icon?: string
}

export interface TeamRecord {
  userName: string
  currentRecord: string
  projectedPoints: number
}

export interface GameMatchup {
  currentTeam: TeamRecord
  opponent: TeamRecord
}

export interface MatchupRecord {
  gameWeek: number
  leagueId: number
  matchup: GameMatchup
}

export interface LeagueWithSettingsUpsert {
  league: LeagueUpsert
  leagueSettings: LeagueSettingsUpsert
}
