export enum SdioLeague {
  ProFootball = 'nfl',
  CollegeFootball = 'cfb',
}

export interface SportsDataCollegeTeam {
  TeamID: number
  Key: string
  Active: boolean
  School: string
  Name: string
  StadiumID: number
  GlobalTeamID: number
  TeamLogoUrl: string
  ConferenceID: number
  Conference: string
  ShortDisplayName: string
}
