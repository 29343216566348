/* eslint-disable prefer-destructuring */
/* eslint-disable @typescript-eslint/dot-notation */

/**
 * All of these should be set in the appropriate .env file
 * These will work in web or react-native as long as we use the
 * "babel-plugin-transform-inline-environment-variables" plugin
 *
 * Which is already added to:
 *  - apps/consumer-native/.babelrc
 */

// The base consumer api url

const CONSUMER_API_URL = process.env['CONSUMER_API_URL'] ?? ''
const WS_URL = process.env['WS_URL'] ?? ''
const CONSUMER_API_KEY = process.env['CONSUMER_API_KEY'] ?? ''
const CONSUMER_API_MIDDLEWARE_URL = process.env['CONSUMER_API_MIDDLEWARE_URL'] ?? ''

// Consumer api url for articles, if not set defaults to base
const CONSUMER_API_URL_ARTICLES = process.env['CONSUMER_API_URL_ARTICLES_ONLY'] ?? CONSUMER_API_URL
const FANTASY_RANKINGS = `${CONSUMER_API_URL}/v1/fantasy/rankings`

// Sping Environment ID from process, Default value ?
const SPRIG_ENV_ID = process.env['SPRIG_ENV_ID'] ?? '8DGDao9_orOj'

const YEARLY_SUB = process.env['YEARLY_SUB'] ?? ''
const MONTHLY_SUB = process.env['MONTHLY_SUB'] ?? ''

const AF_MOBILE_KEY = process.env['AF_MOBILE_KEY'] ?? ''
const AF_APP_ID = process.env['AF_APP_ID'] ?? ''

const GOOGLE_CLIENT_ID = process.env['GOOGLE_CLIENT_ID'] ?? ''
const FACEBOOK_CLIENT_ID = process.env['FACEBOOK_CLIENT_ID'] ?? ''

const ANALYTICS_DAY7 = process.env['ANALYTICS_DAY7'] ?? 7
const ANALYTICS_DAY14 = process.env['ANALYTICS_DAY14'] ?? 14
const ANALYTICS_DAY21 = process.env['ANALYTICS_DAY21'] ?? 21

const LOGIN_URL = process.env['LOGIN_URL'] ?? ''

const PFF_DOMAIN = process.env['PFF_DOMAIN'] ?? 'https://www.pffstaging.com'

const PROXY_ENABLED = !!process.env['PROXY_ENABLED']

const LD_MOBILE_KEY = process.env['LD_MOBILE_KEY'] ?? ''
const NEXT_PUBLIC_LD_CLIENT_SIDE_ID = process.env['NEXT_PUBLIC_LD_CLIENT_SIDE_ID'] ?? ''
const LD_CLIENT_SIDE_ID = process.env['NEXT_PUBLIC_LD_CLIENT_SIDE_ID'] || process.env['LD_CLIENT_SIDE_ID'] || ''
const LD_SDK_KEY = process.env['LD_SDK_KEY'] ?? ''
const COSMOS_URL = process.env['COSMOS_URL'] ?? 'https://dashboard.pff.com'
const GROOT_URL = process.env['GROOT_URL'] ?? 'https://auth.pff.com'

const MERLIN_URL = process.env['MERLIN_URL'] ?? ''
const SUBSCRIBE_URL = process.env['SUBSCRIBE_URL'] ?? 'https://subscribe.pff.com'

export const envConfig = {
  CONSUMER_API_URL,
  CONSUMER_API_MIDDLEWARE_URL,
  CONSUMER_API_KEY,
  CONSUMER_API_URL_ARTICLES,
  FANTASY_RANKINGS,
  SPRIG_ENV_ID,
  YEARLY_SUB,
  MONTHLY_SUB,
  AF_MOBILE_KEY,
  AF_APP_ID,
  GOOGLE_CLIENT_ID,
  FACEBOOK_CLIENT_ID,
  ANALYTICS_DAY7,
  ANALYTICS_DAY14,
  ANALYTICS_DAY21,
  LOGIN_URL,
  WS_URL,
  PFF_DOMAIN,
  PROXY_ENABLED,
  LD_MOBILE_KEY,
  NEXT_PUBLIC_LD_CLIENT_SIDE_ID,
  LD_CLIENT_SIDE_ID,
  LD_SDK_KEY,
  COSMOS_URL,
  MERLIN_URL,
  GROOT_URL,
  SUBSCRIBE_URL,
}
