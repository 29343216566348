import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit'

export const BOTTOM_DRAWER_KEY = 'bottomdrawer'

type BottomDrawerSubscriptWorkflow = 'betting'

export interface BottomDrawerState {
  id: string
  isOpen: boolean
  subscriptionWorkflow?: BottomDrawerSubscriptWorkflow
}

export interface BottomDrawerRootState {
  [BOTTOM_DRAWER_KEY]: ReturnType<typeof bottomDrawerSlice.reducer>
}

export interface OpenBottomDrawerPayload {
  id: string
  subscriptionWorkflow?: BottomDrawerSubscriptWorkflow
}

export interface CloseBottomDrawerPayload {
  id: string
}

export const bottomDrawerAdapter = createEntityAdapter<BottomDrawerState>({
  selectId: (drawer) => drawer.id,
})

export const initialBottomDrawerState = bottomDrawerAdapter.getInitialState()

export const bottomDrawerSlice = createSlice({
  name: BOTTOM_DRAWER_KEY,
  initialState: initialBottomDrawerState,
  reducers: {
    openBottomDrawer: (state, { payload }: PayloadAction<OpenBottomDrawerPayload>) => {
      bottomDrawerAdapter.upsertOne(state, { ...payload, isOpen: true })
    },
    closeBottomDrawer: (state, { payload }: PayloadAction<CloseBottomDrawerPayload>) => {
      bottomDrawerAdapter.updateOne(state, { id: payload.id, changes: { isOpen: false } })
    },
  },
})

export const bottomDrawerReducer = bottomDrawerSlice.reducer
export const bottomDrawerActions = bottomDrawerSlice.actions

export const getBottomDrawerState = (state: BottomDrawerRootState) => state[BOTTOM_DRAWER_KEY]

export const bottomDrawerSelectors = bottomDrawerAdapter.getSelectors(getBottomDrawerState)
