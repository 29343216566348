import { ProfileFormFields } from '../account'

export const DATE_OPTIONS = Array.from({ length: 31 }, (_, i) => i + 1).map((val) => {
  return { text: `${val}`, value: `${val}`.padStart(2, '0') }
})

export enum Month {
  January = 'Jan',
  February = 'Feb',
  March = 'Mar',
  April = 'Apr',
  May = 'May',
  June = 'Jun',
  July = 'Jul',
  August = 'Aug',
  September = 'Sept',
  October = 'Oct',
  November = 'Nov',
  December = 'Dec',
}

export const MONTH_OPTIONS = Object.values(Month).map((month, index) => {
  const monthValue = `${index + 1}`
  return { text: month, value: monthValue.padStart(2, '0') }
})

const MAX_AGE_ALLOWED = 120
const STARTING_YEAR = new Date().getFullYear() - 1

export const YEAR_OPTIONS = Array.from({ length: MAX_AGE_ALLOWED }, (_, i) => STARTING_YEAR - i).map((val) => {
  return { text: `${val}`, value: `${val}` }
})

export enum DropdownInteractiveKeys {
  ESCAPE = 'Escape',
  ARROW_DOWN = 'ArrowDown',
  ARROW_UP = 'ArrowUp',
  ENTER = 'Enter',
  SPACE = ' ',
  TAB = 'Tab',
}

export const DropdownOpenKeys = [
  DropdownInteractiveKeys.ENTER,
  DropdownInteractiveKeys.SPACE,
  DropdownInteractiveKeys.ARROW_DOWN,
  DropdownInteractiveKeys.ARROW_UP,
]

export const DropdownKeysCodes = {
  [DropdownInteractiveKeys.ENTER]: 13,
  [DropdownInteractiveKeys.SPACE]: 32,
  [DropdownInteractiveKeys.ARROW_DOWN]: 40,
  [DropdownInteractiveKeys.ARROW_UP]: 38,
  [DropdownInteractiveKeys.ESCAPE]: 27,
  [DropdownInteractiveKeys.TAB]: 9,
}

export const GENDER_OPTIONS = [
  { text: 'Male', value: 'male' },
  { text: 'Female', value: 'female' },
  { text: 'Non-Binary', value: 'nonBinary' },
  { text: 'Prefer Not to Say', value: 'preferNotToSay' },
]

export enum FocusAction {
  FOCUS = 'FOCUS',
  BLUR = 'BLUR',
}

export interface ProfileFormInput {
  firstName: string
  lastName?: string
  email: string
  dob?: string
  gender?: string
}

export interface ProfileInputFieldArgs {
  name: keyof ProfileFormInput
  label: ProfileFormFields
  isRequired?: boolean
  disabled?: boolean
  type?: string
  errorMessage?: string
}

export const FREE = 'Free'
