export enum AccountPages {
  Overview = 'Account Overview',
  Profile = 'Account Profile',
}

export enum ProfileFormFields {
  Month = 'Month',
  Day = 'Day',
  Year = 'Year',
  FirstName = 'First Name',
  LastName = 'Last Name',
  Email = 'Email',
  Gender = 'Gender',
  DateOfBirth = 'Date Of Birth',
}

export const DatePickerDropdownFields = [ProfileFormFields.Month, ProfileFormFields.Day, ProfileFormFields.Year]
